import React from "react";
import Home from "../component/Home/Home";
import LayoutWrapper from "../component/LayoutWrapper/Index";
import "../styles/global.css";
import citySpecificImage from "../images/cities/scripps_ranch.png";

const App = () => {
  const pageData = {
    residentialRealtors: {
      title: "Realtors in Scripps Ranch, CA",
      subtitle: "With Your Best Interests At Heart",
      description:
        "This is about more than a deal—it's about your family's happiness and building a great future. So whether you're interested in Scripps Ranch homes for sale, are looking to sell your house, or would like to explore property investment options, our experienced residential real estate professionals will connect you with the best opportunities. And we'll do it with heart because we're a family too.",
    },
    findNextHome: {
      card1Title: "Houses for Sale in Scripps Ranch",
      card2Title: "What Is Your Home Worth?",
    },
    meetFamily: {
      title: "Meet Our Family",
      subtitle:
        "We’re dedicated realtors who help families like yours throughout the homeownership journey—from buying real estate in Scripps Ranch, CA, to selling to investing. We’re advisors who are in your corner from day one.",
    },
    citySpecific: {
      titlePartA: "Serving the",
      titlePartB: "Scripps Ranch Community",
      p1: "From its rich history, convenient location near the ocean and just 20 minutes from Downtown San Diego, and beautiful weather, Scripps Ranch is a fantastic place to live or invest in. There is so much to do and see in this beautiful community, including spending a day boating or fishing at Lake Miramar or taking in a show at the Scripps Ranch Theatre.",
      p2: "Here at the Selby Team, we have all of your Scripps Ranch, CA, real estate needs covered. From buying to selling to investing, we have the options you’re looking for. So reach out to our friendly professionals to learn more today.",
      image: citySpecificImage,
    },
    cta: {
      description:
        "Connect with a top-rated team of Scripps Ranch realtors who listen, are readily available, and will work tirelessly for you.",
    },
  };
  return (
    <LayoutWrapper
      title="Scripps Ranch Homes for Sale | Real Estate | Selby Realtors"
      desc="Searching for homes for sale in Scripps Ranch? The Selby Team has the newest listings for this beautiful San Diego-area community."
      headerLogo="../../images/Selby_logo.svg"
    >
      <Home pageData={pageData} />
    </LayoutWrapper>
  );
};

export default App;
